import React from 'react'
import { body, list } from './index.module.css';

export default ({ pageContext, ...props }) => {
  const decks = pageContext.decks
    .map(d => ({ ...d.node, date: new Date(d.node.date) }))
    .sort((a, b) => b.date - a.date);

  return (
    <div className={ body }>
      <h1>Presentations</h1>
      <div className={ list }>
        {
          decks.map(({ slug, title, date }) => (
            <a key={ slug } href={ slug }>
              <span>{ date.toISOString().slice(0, 10) }. </span>
              <span>{ title }</span>
            </a>
          ))
        }
      </div>
    </div>
  );
}
